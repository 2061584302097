import { ExpandMoreRounded } from "@mui/icons-material";
import { Box, Divider, Typography, useScrollTrigger } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import clsx from "lib/clsx";
import { INavItem } from "models/main/shared";
import Link from "next/link";
import { Dispatch, SetStateAction } from "react";

interface IProps extends INavItem {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  setAnchorEl: Dispatch<SetStateAction<Element | null>>;
  activePath: boolean;
}

const NavLinkText = ({
  title,
  url,
  children,
  open,
  handleOpen,
  handleClose,
  setAnchorEl,
  activePath,
}: IProps) => {
  const theme = useTheme();
  const navSticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const hasMenu = !!children?.length;

  const hoverEffect: SxProps = {
    "&::after, & + .menu-expand::after": !activePath
      ? {
          content: "''",
          position: "absolute",
          left: "auto",
          bottom: -6,
          right: 0,
          width: 0,
          height: 3,
          borderRadius: 10,
          visibility: "hidden",
          opacity: 0,
          transition: "all 0.7s ease",
          bgcolor: "var(--palette-secondary-main)",
          [theme.breakpoints.down("md")]: {
            bottom: -2,
            height: 2,
          },
        }
      : {},
    "&:hover::after, & + .menu-expand:hover::after": {
      visibility: "visible",
      opacity: 1,
      right: "auto",
      left: 0,
      width: "100%",
    },
    "& > *::before": activePath
      ? {
          content: "''",
          position: "absolute",
          left: "50%",
          bottom: -6,
          transform: "translatex(-50%)",
          width: "100%",
          height: 3,
          borderRadius: 10,
          bgcolor: "var(--palette-secondary-main)!important",
          [theme.breakpoints.down("md")]: {
            bottom: -2,
            height: 2,
          },
        }
      : {},
  };

  return (
    <Box
      className="relative flex cursor-pointer items-center"
      component="div"
      ref={(el: HTMLDivElement | null) => setAnchorEl(el)}
      {...(!url
        ? {
            "aria-controls": open ? "nav-expand-menu" : undefined,
            "aria-expanded": open ? "true" : undefined,
            "aria-label": `${title} menu group with a nested menu`.toLowerCase(),
            "aria-haspopup": "menu",
          }
        : {})}
      onClick={() => {
        if (!url) handleOpen();
      }}
      onMouseEnter={() => {
        if (!url) handleOpen();
      }}
      onMouseLeave={() => {
        if (!url) handleClose();
      }}
      sx={{
        "&:hover > div > *::before": !activePath
          ? {
              visibility: "hidden",
              opacity: 0,
              width: 0,
            }
          : {},
      }}
    >
      <Box className={clsx(url && "relative")} sx={hoverEffect}>
        <Typography
          className="relative text-center text-xl font-semibold tracking-tight transition duration-500 md:text-[1.7rem] md:tracking-normal lg:text-[1.8rem]"
          {...(url ? { component: Link, href: url } : {})}
          color="text.secondary"
        >
          {title}
        </Typography>
      </Box>
      {hasMenu && (
        <Box className="menu-expand flex items-center">
          {url && (
            <Divider
              aria-hidden="true"
              className={clsx(
                navSticky ? "bg-text-light" : "bg-text-dark",
                "mx-2.5 h-5 border-r-2 transition duration-500 lg:h-6 xl:h-7",
              )}
              orientation="vertical"
            />
          )}
          <ExpandMoreRounded
            {...(url
              ? {
                  "aria-controls": open ? "nav-expand-menu" : undefined,
                  "aria-expanded": open ? "true" : undefined,
                  "aria-label": `${title} menu expander`.toLowerCase(),
                  "aria-haspopup": "menu",
                  "aria-hidden": "false",
                }
              : {})}
            className={clsx(
              !url && "ml-2.5",
              open && "-rotate-180",
              "text-lg text-dark transition duration-500 lg:text-xl xl:text-2xl",
            )}
            viewBox="6 6 12 12"
            onClick={() => {
              if (url) handleOpen();
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default NavLinkText;
