import { ListItemText, MenuItem, MenuList, Popover } from "@mui/material";
import { INavItem } from "models/main/shared";
import Link from "next/link";
import { useRouter } from "next/router";

interface IProps {
  open: boolean;
  anchorEl: Element | null;
  handleOpen: () => void;
  handleClose: () => void;
  children: INavItem[];
}

const NavExpandMenu = ({ open, anchorEl, handleOpen, handleClose, children }: IProps) => {
  const { pathname } = useRouter();

  return (
    <Popover
      className="pointer-events-none hidden sm:block"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        paper: "rounded-xl bg-background-default p-2 lg:p-2.5 pointer-events-auto",
      }}
      slotProps={{
        paper: {
          onMouseEnter: (e) => {
            handleOpen();
          },
          onMouseLeave: () => {
            handleClose();
          },
        },
      }}
      sx={{ ".MuiPaper-root": { minWidth: anchorEl?.clientWidth } }}
    >
      <MenuList id="nav-expand-menu" disablePadding>
        {children.map(({ title, url }, i) => {
          const activePath = url === pathname;

          return (
            <MenuItem
              key={i}
              role="button"
              className="h-12 rounded-xl md:h-14"
              {...(url ? { component: Link, href: url } : {})}
              selected={activePath}
              onClick={handleClose}
              classes={{ selected: "!bg-primary-main/20" }}
            >
              <ListItemText
                primary={title}
                classes={{
                  primary: "text-xl md:text-[1.8rem]",
                }}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Popover>
  );
};

export default NavExpandMenu;
