import { PageTransition } from "components/shared/layout/page-transition";
import dynamic from "next/dynamic";
import Script from "next/script";
import { ReactNode } from "react";
import { Footer } from "./footer";
import { Navbar } from "./nav";

const DynamicNavDrawer = dynamic(() => import("./nav/drawer/NavDrawer"));

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <PageTransition variant="perspective">
      <DynamicNavDrawer />
      <Navbar />
      <main>{children}</main>
      <Script src={process.env.NEXT_PUBLIC_TIDIO_SCRIPT} strategy="lazyOnload" />
      <Footer />
    </PageTransition>
  );
};

export default MainLayout;
