import { OpenInNew } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import clsx from "lib/clsx";
import { INavItem } from "models/main/shared";
import Link from "next/link";
import { useRouter } from "next/router";

const FooterLink = ({ className, title, url, target }: INavItem & { className?: string }) => {
  const { pathname } = useRouter();

  const activePath = url === pathname;

  const hoverEffect: SxProps = {
    "&::after": {
      content: "''",
      position: "absolute",
      left: "auto",
      bottom: -2,
      right: 0,
      width: 0,
      height: 2,
      borderRadius: 10,
      visibility: "hidden",
      opacity: 0,
      transition: "all 0.7s ease",
      bgcolor: "text.secondary",
    },
    "&:hover::after": {
      visibility: "visible",
      opacity: 1,
      right: "auto",
      left: 0,
      width: "100%",
    },
    "& > p::before": activePath
      ? {
          content: "''",
          position: "absolute",
          top: 24,
          bottom: -2,
          left: "50%",
          transform: "translatex(-50%)",
          width: 30,
          height: 2,
          borderRadius: 10,
          transition: "all 0.7s ease",
          bgcolor: "text.secondary",
        }
      : {},
    "&:hover > p::before": {
      visibility: "hidden",
      opacity: 0,
      width: 0,
    },
  };

  return (
    <li className={clsx("relative flex cursor-pointer items-center", className)}>
      <Typography
        className="relative flex items-center gap-[1.6rem] tracking-tight transition duration-500 md:tracking-normal"
        {...(url ? { component: Link, href: url } : {})}
        {...(target === "_blank" ? { rel: "noopener noreferrer", target: "_blank" } : {})}
        color="text.secondary"
        variant="p2"
        sx={url ? hoverEffect : {}}
      >
        {target === "_blank" && <OpenInNew sx={{ fontSize: "2.4rem" }} />}
        {title}
      </Typography>
    </li>
  );
};

export default FooterLink;
