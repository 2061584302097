import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AnimatePresence, Variants, m } from "framer-motion";
import clsx from "lib/clsx";
import { useAppSelector } from "lib/redux";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { Image } from "../../..";
import { selectSharedLayoutContent } from "../../store/layoutContentSlice";

const perspective: Variants = {
  initial: {
    scale: 0.9,
    y: "-2.5%",
  },
  enter: {
    scale: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 1,
      ease: [0.76, 0, 0.24, 1],
    },
  },
  exit: {
    scale: 0.9,
    y: "-2.5%",
    opacity: 0.5,
    transition: {
      duration: 1,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};

const slideIn: Variants = {
  initial: {
    y: "100vh",
  },
  animate: {
    y: "100vh",
  },
  exit: {
    y: 0,
    transition: {
      delay: 0.4,
      duration: 0.6,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};

const slideOut: Variants = {
  initial: {
    y: 0,
  },
  animate: {
    y: "-100vh",
    transition: {
      delay: 0.25,
      duration: 0.8,
      ease: [0.76, 0, 0.24, 1],
    },
  },
  exit: {
    y: "-100vh",
  },
};

interface IProps {
  children: ReactNode;
  className?: string;
  enablePageTransition?: boolean;
}

const PerspectiveTransition = ({ children, className, enablePageTransition }: IProps) => {
  const {
    logos: { dark: logo },
    settings: { enablePageTransition: enablePageTransitionGlobally },
  } = useAppSelector(selectSharedLayoutContent);

  const { pathname } = useRouter();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  if (
    !smUp ||
    (!enablePageTransitionGlobally && !enablePageTransition) ||
    enablePageTransition === false
  ) {
    return children;
  }

  const Logo = () => <Image className="transform-center absolute" {...logo} placeholder="empty" />;

  return (
    <AnimatePresence mode="wait" initial={false}>
      <m.div key={pathname} className={clsx("hidden bg-black sm:block", className)}>
        <m.div
          className="fixed left-0 top-0 z-10 h-screen w-full origin-bottom bg-primary-dark"
          {...slideIn}
        >
          <Logo />
        </m.div>
        <m.div
          className="fixed left-0 top-0 z-10 h-screen w-full origin-top bg-primary-dark"
          {...slideOut}
        >
          <Logo />
        </m.div>
        <m.div
          className="bg-white"
          initial="initial"
          animate="enter"
          exit="exit"
          variants={perspective}
        >
          {children}
        </m.div>
      </m.div>
    </AnimatePresence>
  );
};

export default PerspectiveTransition;
