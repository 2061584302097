import { ExpandMoreRounded } from "@mui/icons-material";
import { ButtonGroup, useMediaQuery, useScrollTrigger } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button } from "components/shared";
import clsx from "lib/clsx";
import { INavItem } from "models/main/shared";
import Link from "next/link";

const LinkButton = ({ title, url, activePath }: INavItem & { activePath: boolean }) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const navSticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Button
      className={clsx(
        "bg-background-default text-xl text-light transition duration-500 hover:bg-primary-light md:text-[1.8rem]",
        url === "/auth/register" && "bg-primary-light hover:bg-primary-main hover:text-dark",
        activePath && "bg-primary-main text-dark",
      )}
      {...(url ? { LinkComponent: Link, href: url } : {})}
      size={navSticky || lgDown ? "small" : "large"}
      variant="contained"
    >
      {title}
    </Button>
  );
};

interface IProps extends INavItem {
  open: boolean;
  handleOpen: (el: Element) => void;
  activePath: boolean;
}

const NavLinkButton = ({ open, handleOpen, activePath, ...props }: IProps) => {
  const { title, url, children } = props;
  const hasMenu = !!children?.length;

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const navSticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  if (hasMenu) {
    return (
      <>
        <ButtonGroup
          {...(url
            ? {
                "aria-controls": open ? "nav-expand-menu" : undefined,
                "aria-expanded": open ? "true" : undefined,
                "aria-label": `${title} button group with a nested menu`.toLowerCase(),
                "aria-haspopup": "menu",
              }
            : {})}
          variant="contained"
          onClick={(e) => {
            if (!url) handleOpen(e.currentTarget);
          }}
        >
          <LinkButton {...props} activePath={activePath} />
          <Button
            {...(url
              ? {
                  "aria-controls": open ? "nav-expand-menu" : undefined,
                  "aria-expanded": open ? "true" : undefined,
                  "aria-label": `${title} menu button`.toLowerCase(),
                  "aria-haspopup": "menu",
                }
              : {})}
            className={clsx(
              "bg-background-default text-xl text-light transition duration-500 hover:bg-primary-light md:text-[1.8rem]",
              url === "/auth/register" && "bg-primary-light hover:bg-primary-main hover:text-dark",
              activePath && "bg-primary-main text-dark",
            )}
            size={navSticky || lgDown ? "small" : "large"}
            onClick={(e) => {
              if (url) handleOpen(e.currentTarget);
            }}
          >
            <ExpandMoreRounded
              className={clsx(open && "-rotate-180", "text-5xl transition duration-500")}
            />
          </Button>
        </ButtonGroup>
      </>
    );
  }

  return <LinkButton {...props} activePath={activePath} />;
};

export default NavLinkButton;
