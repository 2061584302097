import { ReactNode } from "react";
import { PerspectiveTransition, SharedTransition } from "./variants";

interface IProps {
  children: ReactNode;
  className?: string;
  variant: "perspective" | "shared";
  enablePageTransition?: boolean;
}

const PageTransition = ({ children, className, variant, enablePageTransition }: IProps) => {
  if (variant === "perspective") {
    return (
      <PerspectiveTransition className={className} enablePageTransition={enablePageTransition}>
        {children}
      </PerspectiveTransition>
    );
  }

  return (
    <SharedTransition className={className} enablePageTransition={enablePageTransition}>
      {children}
    </SharedTransition>
  );
};

export default PageTransition;
