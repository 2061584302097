import { INavItem } from "models/main/shared";
import { useRouter } from "next/router";
import { useState } from "react";
import { NavExpandMenu, NavLinkButton, NavLinkText } from ".";

const NavLink = (props: INavItem) => {
  const { url, variant, children } = props;
  const { pathname } = useRouter();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [expandMenuOpen, setExpandMenuOpen] = useState(false);

  const hasMenu = !!children?.length;
  const activePath =
    url === pathname || !!children?.some((c) => c.url?.split("/")[1] === pathname.split("/")[1]);

  const handleOpen = () => {
    setExpandMenuOpen(true);
  };

  const handleClose = () => {
    setExpandMenuOpen(false);
  };

  return (
    <li>
      {variant === "text" ? (
        <NavLinkText
          {...props}
          open={expandMenuOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          setAnchorEl={setAnchorEl}
          activePath={activePath}
        />
      ) : (
        <NavLinkButton
          {...props}
          open={expandMenuOpen}
          handleOpen={handleOpen}
          activePath={activePath}
        />
      )}
      {expandMenuOpen && hasMenu && (
        <NavExpandMenu
          open={expandMenuOpen}
          anchorEl={anchorEl}
          handleOpen={handleOpen}
          handleClose={handleClose}
        >
          {children}
        </NavExpandMenu>
      )}
    </li>
  );
};

export default NavLink;
