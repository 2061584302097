import { interpolate } from "flubber";
import { MotionValue, useTransform } from "framer-motion";

const useFlubber = (
  value: MotionValue<number>,
  progressRange: number[],
  paths: string[],
  maxSegmentLength = 2,
) => {
  if (progressRange.length < 2) {
    throw new Error("The `progressRange` parameter must include at least 2 values.");
  }

  if (paths.length < 2) {
    throw new Error("The `paths` parameter must include at least 2 values.");
  }

  if (progressRange.length !== paths.length) {
    throw new Error(
      "The `progressRange` parameter and the `paths` parameter must have the same number of items.",
    );
  }

  const path = useTransform(value, progressRange, paths, {
    mixer: (a, b) => interpolate(a, b, { maxSegmentLength }),
  });

  return path;
};

export default useFlubber;
