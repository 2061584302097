import { AppBar, Box, Toolbar, useScrollTrigger } from "@mui/material";
import { Image } from "components/shared";
import { selectSharedLayoutContent } from "components/shared/layout/store/layoutContentSlice";
import clsx from "lib/clsx";
import { useAppSelector } from "lib/redux";
import Link from "next/link";
import { NavLink } from ".";
import { selectMainLayoutContent } from "../store/layoutContentSlice";

const Navbar = () => {
  const { logos } = useAppSelector(selectSharedLayoutContent);
  const {
    mainNav: { items },
  } = useAppSelector(selectMainLayoutContent);

  const navSticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <AppBar
      className={clsx(
        "sticky w-full bg-primary-dark shadow-none transition-all duration-500",
        navSticky && "bg-primary-dark shadow-sm",
      )}
      component="nav"
    >
      <Toolbar
        className={clsx(
          "my-1.5 transition-all duration-500",
          navSticky ? "sm:my-0 md:my-0" : "sm:my-0 md:my-4",
        )}
        disableGutters
      >
        <Box
          className="container flex w-full items-center justify-between gap-4"
          sx={(theme) => ({ [theme.breakpoints.down("md")]: { px: "20px" } })}
        >
          <Link href="/">
            <Image
              {...logos.dark}
              className={clsx(
                "h-auto select-none drop-shadow-sm transition-all duration-500",
                navSticky ? "w-40 md:w-52" : "w-40 md:w-52 xl:w-60",
              )}
              placeholder="empty"
            />
          </Link>
          <ul className="hidden items-center gap-5 sm:flex md:gap-8 lg:gap-12">
            {items.map((link, i) => (
              <NavLink key={i} {...link} />
            ))}
          </ul>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
