import { styled, useScrollTrigger } from "@mui/material";
import { Button } from "components/shared";
import { m } from "framer-motion";
import { useDebounce } from "hooks";
import clsx from "lib/clsx";
import { useAppSelector } from "lib/redux";
import { selectMainNavState } from "./store/stateSlice";

const Path = styled(m.path)(() => ({
  fill: "transparent",
  strokeWidth: 3,
  strokeLinecap: "round",
}));

interface IProps {
  toggle: () => void;
}

export const MenuToggle = ({ toggle }: IProps) => {
  const { navDrawerOpen } = useAppSelector(selectMainNavState);

  const navSticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const delayedTransition = useDebounce(navDrawerOpen, navDrawerOpen ? 0 : 1000);

  return (
    <Button
      aria-label="open navigation drawer"
      className={clsx(
        "mr-4 max-w-36 stroke-text-dark px-3 font-semibold text-dark transition-colors",
        navDrawerOpen
          ? "duration-300"
          : !delayedTransition && navSticky
            ? "duration-700"
            : "duration-300",
      )}
      size="small"
      onClick={toggle}
      endIcon={
        <svg className="stroke-text-inherit mr-1" width="14" height="14" viewBox="0 0 20 20">
          <Path
            variants={{
              closed: { d: "M 2 2.5 L 20 2.5" },
              open: { d: "M 3 16.5 L 17 2.5" },
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: "M 2 16.346 L 20 16.346" },
              open: { d: "M 3 2.5 L 17 16.346" },
            }}
          />
        </svg>
      }
    >
      {navDrawerOpen ? "Close" : "Menu"}
    </Button>
  );
};
